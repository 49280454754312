
import { defineComponent, reactive, computed, toRefs } from '@vue/composition-api'
import { Paperset } from '@/models'
import { showDateTimeInfo } from '@/utils/formatPaperInfo'

export default defineComponent({
  name: 'PapersetList',
  props: {
    papersets: { type: Array as () => Paperset[], required: true },
    userEmail: { type: String, default: undefined },
    allowSearch: { type: Boolean, default: false }
  },
  setup (props) {
    const state: { match: string; paperSets: Paperset[] } = reactive({
      match: '',
      paperSets: computed(() => {
        const match = state.match.toLowerCase()
        const papersets =
          match.length > 1
            ? props.papersets?.filter(
              (c) =>
                c.name.toLowerCase().includes(match) ||
                  c.description?.toLowerCase().includes(match)
            )
            : props.papersets

        if (papersets === undefined) return []

        return papersets.map((paperset) => {
          let ownerName =
            props.userEmail === paperset.owner ? 'Me' : paperset.owner
          if (ownerName !== 'Me' && Array.isArray(paperset.peopleInfo)) {
            const pidx = paperset.peopleInfo
              .map((p) => p.email)
              .indexOf(paperset.owner)
            if (pidx !== -1) {
              ownerName = paperset.peopleInfo[pidx].name
            }
          }
          return { ownerName, ...paperset }
        })
      })
    })

    return { ...toRefs(state), showDateTimeInfo }
  },
  methods: {
    toPapersetURL (ps: Paperset) {
      this.$router.push('/s/' + ps.id)
    },
    getHighlightCount (ps: Paperset) {
      return ps.annotations ? Object.keys(ps.annotations).length : 0
    }
  }
})
