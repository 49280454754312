
import AppBar from '@/components/AppBar'
import { UserProfileDB, PapersetDB } from '@/Firebase'
import { defineComponent, reactive, computed, onMounted, toRefs, watch } from '@vue/composition-api'
import { UserProfile, Paperset, Invitation, PaperId, PaperInfo } from '@/models'
// import { PaperDB } from '@/Firebase'
import PapersetList from '@/components/PapersetList.vue'
import AccountProfile from '@/components/AccountProfile.vue'
import { showAuthorNames, showDateTimeInfo } from '@/utils/formatPaperInfo'
// import NotePad from '@/InterNote/NotePad.vue'

const LinkPrefix = {
  Paperset: 's'
}

type AccountData = {
  subpage: string;
  drawerLeft: boolean;
  userProfile: UserProfile | null;
  selectedSubpage: number;
  myCollections: Paperset[];
  sharedCollections: Paperset[];
  invitations: Invitation[];
  papersets: Paperset[];
  papersetsSharedWithOthers: Paperset[];
  papers: PaperInfo[];
}

export default defineComponent({
  name: 'Account',
  components: {
    AppBar,
    AccountProfile,
    PapersetList
    // NotePad
  },
  setup (props, { root }) {
    const subpages: {id: string; text: string; icon: string; type: string}[] = [
      { id: 'all-papersets', text: 'All Papersets', icon: 'mdi-all-inclusive', type: 'sets' },
      { id: 'my-papersets', text: 'My Papersets', icon: 'mdi-text-box-multiple', type: 'sets' },
      { id: 'shared-with-me', text: 'Shared with Me', icon: 'mdi-account-group', type: 'sets' },
      { id: 'shared-with-others', text: 'Shared with Others', icon: 'mdi-share-variant', type: 'sets' },
      { id: 'recently-read', text: 'Recently Read', icon: 'mdi-history', type: 'papers' },
      { id: 'newly-added', text: 'Newly Added', icon: 'mdi-bookmark-plus-outline', type: 'papers' },
      { id: 'profile', text: 'Profile', icon: 'mdi-account-circle', type: 'account' },
      { id: 'settings', text: 'Settings', icon: 'mdi-cog', type: 'account' }
    ]

    const state: AccountData = reactive({
      subpage: root.$route.params.subpage ?? 'all-papersets',
      drawerLeft: true,
      userProfile: null,
      selectedSubpage: subpages.map(s => s.id).indexOf(root.$route.params.subpage) ?? 0,
      myCollections: [],
      sharedCollections: [],
      invitations: [],
      papersetsSharedWithOthers: computed(() => {
        return state.myCollections.filter(c => {
          return (Array.isArray(c.editors) && c.editors.length > 0) ||
          (Array.isArray(c.readers) && c.readers.length > 0)
        })
      }),
      papersets: computed(() => {
        let papersets: Paperset[] = []
        switch (state.subpage) {
        case 'my-papersets':
          papersets = state.myCollections
          break
        case 'shared-with-me':
          papersets = state.sharedCollections
          break
        case 'shared-with-others':
          papersets = state.papersetsSharedWithOthers
          break
        case 'all-papersets':
          papersets = state.myCollections.concat(state.sharedCollections)
          break
        default:
          papersets = state.myCollections.concat(state.sharedCollections)
          break
        }
        // FIXME:
        // if (papersets.length > 1) {
        //   papersets.sort((a: Paperset, b: Paperset) => b.lastUpdated > a.lastUpdated ? 1 : -1)
        // }
        return papersets
      }),
      papers: computed(() => {
        if (state.subpage !== 'recently-read' && state.subpage !== 'newly-added') return []
        const paperType = (state.subpage === 'recently-read') ? 'readHistory' : 'newlyAdded'
        if (
          state.userProfile !== null &&
          state.userProfile[paperType] !== undefined &&
          Array.isArray(state.userProfile[paperType])
        ) {
          const papers = new Map(Object.entries(state.userProfile.papers))
          const pps = state.userProfile[paperType]?.map((paperId: PaperId) =>
            papers.get(paperId)
          ) as PaperInfo[] ?? []

          if (pps.length > 2) {
            pps.sort((a: PaperInfo, b: PaperInfo) => b.timestamp > a.timestamp ? 1 : -1)
          }
          return pps
        }

        return []
      })
    })

    const hasInvitations = computed(() => {
      return state.userProfile && state.invitations.length
    })

    watch(
      () => state.selectedSubpage,
      (subpage, prevSubpage) => {
        if (subpage === undefined || prevSubpage === undefined) return
        state.subpage = subpages[subpage].id
        if (subpage !== prevSubpage && state.subpage !== root.$route.params.subpage) {
          root.$router.push('/account/' + state.subpage)
        }
      }
    )

    watch(
      () => root.$route.params.subpage,
      (subpage) => {
        const subpageIndex = subpages.map(s => s.id).indexOf(subpage) || 0
        if (subpageIndex !== state.selectedSubpage) {
          state.selectedSubpage = subpageIndex
        }
      }
    )

    async function acceptInvitation (invitation: Invitation) {
      await UserProfileDB.acceptInvitation(invitation)
      if (invitation.paperset.id !== undefined) {
        const collectionData = await PapersetDB.get(invitation.paperset.id)
        if (collectionData !== undefined) {
          const collection = Object.assign({ canEdit: invitation.permission === 'can edit' }, collectionData)
          state.sharedCollections.push(collection)
        }

        state.invitations = state.invitations
          .filter(inv => inv.paperset.id !== invitation.paperset.id)
      }
    }

    function declineInvitation (invitation: Invitation) {
      UserProfileDB.declineInvitation(invitation)
      state.invitations = state.invitations
        .filter(inv => inv.paperset.id !== invitation.paperset.id)
    }

    function getPapersetName (cid: string) {
      const ps = state.papersets.find(p => p.id === cid)
      return (ps !== undefined) ? ps.name : ''
    }

    onMounted(async () => {
      state.userProfile = await UserProfileDB.getProfile()
      if (state.userProfile?.papersets !== undefined) {
        const papersets = await UserProfileDB.getPapersets(
          Object.keys(state.userProfile?.papersets)
        )
        state.myCollections = papersets
          .filter(ps => ps.owner === state.userProfile?.email)
        state.sharedCollections = papersets
          .filter(ps => ps.owner !== state.userProfile?.email)
      }
    })

    return {
      ...toRefs(state),
      LinkPrefix,
      subpages,
      hasInvitations,
      acceptInvitation,
      declineInvitation,
      showAuthorNames,
      showDateTimeInfo,
      getPapersetName
    }
  },
  methods: {
    onNewPaperSet (newPaperSet: Paperset) {
      this.$router.push('/collection/' + newPaperSet.id)
    }
  }
})
