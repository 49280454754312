
import { defineComponent, reactive, toRefs, computed, onMounted } from '@vue/composition-api'
import { UserProfile } from '@/models'
import { uploadUserPhoto, getUserPhotoUrl, UserProfileDB, getUser } from '@/Firebase'

type AccountProfileData = {
  showImageUploadIcon: boolean
  nameInitials: string | undefined
  uploadedPhoto: string | null
  fileReader: FileReader
  affiliation: string
  valid: boolean
  bio: string
  isEditingProfile: boolean
}

export default defineComponent({
  name: 'AccountProfile',
  props: {
    userProfile: { type: Object as () => UserProfile, required: true }
  },
  setup (props, { refs }) {
    const state: AccountProfileData = reactive({
      showImageUploadIcon: false,
      nameInitials: computed(() => {
        return props.userProfile?.displayName.split(' ').slice(-2).map(s => s[0].toUpperCase()).join('')
      }),
      uploadedPhoto: props.userProfile?.uploadedPhoto || null,
      fileReader: new FileReader(),
      bio: props.userProfile?.bio || '',
      affiliation: props.userProfile?.affiliation || '',
      valid: true,
      updated: false,
      isEditingProfile: false
    })

    const rules = {
      affiliationRules: [(v: string) => v.length <= 80 || 'Affiliation must be less than 80 characters'],
      bioRules: [(v: string) => v.length <= 512 || 'Affiliation must be less than 512 characters']
    }

    const selectImageFile = () => {
      console.log(refs.imageFileInput)

      if (refs.imageFileInput !== undefined) {
        (refs.imageFileInput as HTMLElement).click()
      }
    }

    const loadImageFile = (e: Event) => {
      const target = e.target as HTMLInputElement
      if (target.files !== null && target.files[0] !== undefined) {
        state.fileReader.readAsArrayBuffer(target.files[0])
      }
    }

    const drawImageOnCanvas = () => {
      const image = new Image()
      image.onload = async () => {
        console.log('image onload')
        const canvas = refs.ImageCanvas as HTMLCanvasElement
        canvas.height = 128
        canvas.width = image.width / image.height * 128
        const ctx = canvas.getContext('2d')
        if (ctx !== null) {
          ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, 128)
          const photoUrl = canvas.toDataURL('image/png')
          const user = getUser()
          if (user !== null) {
            await uploadUserPhoto(user.uid, photoUrl)
            state.uploadedPhoto = await getUserPhotoUrl(user.uid)
          }
        }
      }
      // console.log(state.fileReader.result)
      if (state.fileReader.result !== null) {
        const blob = new Blob([state.fileReader.result], { type: 'image/png' })
        const urlCreator = window.URL || window.webkitURL
        const imageUrl = urlCreator.createObjectURL(blob)
        image.src = imageUrl
      }
    }

    const updateProfile = () => {
      state.isEditingProfile = false
      const { affiliation, bio, uploadedPhoto } = state
      if (uploadedPhoto !== null) {
        UserProfileDB.update({ affiliation, bio, uploadedPhoto })
      }
    }

    onMounted(() => {
      state.fileReader.onload = drawImageOnCanvas
    })

    return {
      ...toRefs(state),
      ...rules,
      selectImageFile,
      loadImageFile,
      drawImageOnCanvas,
      updateProfile
    }
  }
})
